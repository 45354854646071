.parallax-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-basis: 100%;
  height: 70vh;
  max-width: 600px;
  margin: 0 auto;
  .scene {
    padding: 0;
    margin: 0;
    img {
      display: block;
      width: 100%;
    }
    .layer {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
    .layer:nth-child(1) {
      position: relative;
    }
    .layer:nth-child(2) {
      left: 50%;
      width: 75%;
      margin: 0 auto;
      padding-top: 20px;
    }
    .layer:nth-child(2) img {
      position: relative;
      left: 10%;
    }
  }
}

@media (min-width: 500px) {
  .parallax-container {
    //margin-top: -10%;
    //padding: 0px;
  }
}
