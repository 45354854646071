@import "variables.scss";
@import "mixins.scss";
@import "navbar.scss";
@import "masthead.scss";
@import "buttons.scss";
@import "about.scss";

body {
  font-family: "Nunito";
  letter-spacing: 0.0625em;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

footer {
  padding: 5rem 0;
}

html,
body {
  width: 100%;
  height: 100%;
  color: $offwhite;
}
#home,
#root {
  height: 100%;
}

body {
  font-family: "Lora", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: black;
}
section {
  min-height: 100%;
  min-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 35px;
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}
p {
  margin: 0 0 25px;
  font-size: 18px;
  line-height: 1.5;
}
@media (min-width: 768px) {
  p {
    margin: 0 0 35px;
    font-size: 20px;
    line-height: 1.6;
  }
}
a {
  color: $primary;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #996300;
}
.light {
  font-weight: 300;
}
.btn-circle {
  width: 70px;
  height: 70px;
  //border: 2px solid $gray-500;
  border-radius: 100% !important;
  font-size: 40px;
  color: $primary;
  background: transparent;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}
.btn-circle {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  i.fa {
    vertical-align: middle;
    font-size: 48px;
    line-height: 70px;
    display: block;
  }
  &:hover,
  &:focus {
    outline: none;
    //color: $offwhite;
    //background: rgba(255, 255, 255, 0.1);
    i.fa {
      -webkit-animation-name: pulse;
      -moz-animation-name: pulse;
      -webkit-animation-duration: 1.5s;
      -moz-animation-duration: 1.5s;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
    }
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.content-section {
  padding-top: 100px;
}

@media (min-width: 767px) {
  .container {
    width: 100%;
  }
  .download-section {
    padding: 100px 0;
  }
  #map {
    height: 400px;
    margin-top: 250px;
  }
}
.btn {
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
}
.btn-default {
  border: 1px solid $primary;
  color: $primary;
  background-color: transparent;
}
.btn-default:hover,
.btn-default:focus {
  border: 1px solid $primary;
  outline: none;
  color: black;
  background-color: $primary;
}

footer {
  padding: 50px 0;
}
footer p {
  margin: 0;
}
::-moz-selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(255, 255, 255, 0.2);
}
::selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(255, 255, 255, 0.2);
}
img::selection {
  background: transparent;
}
img::-moz-selection {
  background: transparent;
}
body {
  webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}

.footer-image {
  background: url(/img/walking_ninja.gif);
  background-size: contain;
  margin: auto;
  margin-bottom: 10px;
  display: block;
  width: 75px;
  height: 75px;
}
