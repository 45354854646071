.about-section {
  padding-top: 5rem;
  background: linear-gradient(
    to bottom,
    $black 0%,
    #{fade-out($black, 0.1)} 75%,
    #{fade-out($black, 0.2)} 100%
  );
  p {
    margin-bottom: 2rem;
  }
}

ul.banner-social-buttons {
  margin-top: 0;
  padding-top: 0;
  li {
    display: inline;
    margin-left: 15px;
    a {
      border: 1px solid orange;
      border-radius: 0;
      color: orange;
      background-color: transparent;
      text-transform: uppercase;
      font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 400;
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.3333333;
    }
  }
}
@media (max-width: 767px) {
  ul.banner-social-buttons li {
    margin-bottom: 20px;
    padding: 0;
  }
  ul.banner-social-buttons li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1199px) {
  ul.banner-social-buttons {
    margin-top: 15px;
  }
}
